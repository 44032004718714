import { Controller } from "stimulus"
import { CountUp } from 'countup.js';

Number.prototype.countDecimals = function () {
    if(Math.floor(this.valueOf()) === this.valueOf()) return 0;
    return this.toString().split(".")[1].length || 0; 
}

export default class extends Controller {
    static targets = [ "num" ];

    initialize() {
        this.numTargets.forEach(num => {
            const toNumber = parseFloat(num.innerText);
            const decimalPlaces = toNumber.countDecimals();

            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if(entry.isIntersecting) {
                        const countUp = new CountUp(num, toNumber, {
                            decimalPlaces: decimalPlaces,
                            useEasing: true
                        });
                        countUp.start();
                        observer.unobserve(entry.target);
                    }
                })
            });
            observer.observe(num);
        });
    }
}