import { Controller } from "stimulus"
import { af as AF } from '@gladeye/af';
import { isMobile } from './../utils/is-mobile';
import { round } from './../utils';

export default class extends Controller {
    static targets = [ "image" ];
    x = 0;
    y = 0;
    
    precise = false;

    intensity = 0.5;

    to = {
        x: 0,
        y: 0,
    };

    initialize() {
        if (isMobile) return; // Disable on Tablets & Mobile
        this.init();
        this.af = AF();
        this.af.addRead(this.read);
        this.af.addWrite(this.write);
    }
    
    init = () => {
        // this.element.addEventListener('mouseenter', this.onEnter);
        window.addEventListener('mousemove', this.onMove);
        // this.element.addEventListener('mouseleave', this.onLeave);
    }

    onEnter = (e) => {
        this.active = true;
    }

    onMove = (e) => {
        // const { width: w, height: h, left: l, top: t } = this.rect;
        const { innerWidth: w, innerHeight: h } = window;

        const x =  e.clientX - w/2;
        const y = e.clientY - h/2;

        console.log(y);
        
        // console.log(x, y);
        this.to.x = x * this.intensity; //x > 0 ? (w/2 - Math.abs(x)) : (Math.abs(x) - w/2);
        this.to.y = y * this.intensity; //y > 0 ? (h/2 - Math.abs(y)) : (Math.abs(y) - h/2);
    }

    onLeave = (e) => {
        this.active = false;
        
        this.to.x = 0;
        this.to.y = 0;
    }
    
    read = () => {
        this.rect = this.element.getBoundingClientRect();
    }

    write = () => {
        if (Math.abs(this.to.x - this.x) < 0.2 && Math.abs(this.to.y - this.y) < 0.2) {
            
            if (!this.precise) {
                const x = round(this.x, 10);
                const y = round(this.y, 10);
                this.precise = true;
                this.render(x, y);
            }

            return;
        }
        
        this.precise = false;
        this.x += (this.to.x - this.x) * .08;
        this.y += (this.to.y - this.y) * .08;
        
        const x = round(this.x, 10000);
        const y = round(this.y, 10000);

        this.render(x, y);

    }

    render(x, y) {
        this.imageTarget.style.transform = `translate3d(${x}px, ${y}px, 0)`;
    }
}