import { Controller } from "stimulus"
import anime from 'animejs/lib/anime.es.js';
import { af as AF } from '@gladeye/af';
import { isMobile } from './../utils/is-mobile';

const CLASS_CURRENT = 'is-current';

export default class extends Controller {
    static targets = [ "tile", "wrap", "wrap2" ];
    current = null;
    anim = { pause: () => {}};

    progress = 0.5;

    initialize() {
        this.af = AF();
    }

    connect() {
        this.init();
    }

    init = () => {
        if (isMobile) return; // Disable on Tablets & Mobile
        if (window.innerWidth <= 600) return; // Disable on below 600

        this.tileTargets.forEach(this.initTile);
        this.af.addWrite(this.write);
        const x = 10;

        anime.set(this.wrapTargets[0], {
            translateX: `${x}%`,
            translateZ: 0,
        });

        anime.set(this.wrap2Targets[0], {
            translateX: `${-x}%`,
            translateZ: 0,
        });

        anime.set(this.tileTargets[1], {
            opacity: .3
        });

        this.tl = anime.timeline({
            autoplay: false,
            easing: 'linear',
            duration: 1000,
        })
        // Left one selected
        // .add({
        //     targets: this.wrap2Targets[0],
        //     translateX: '10%',
        //     duration: 1000,
        //     translateZ: 0
        // })
        // Neutral
        .add({
            targets: [this.wrap2Targets, this.wrapTargets, this.tileTargets],
            translateX: '0%',
            translateZ: 0,
            opacity: 1
        })
        // Right one selected
        .add({
            targets: this.wrapTargets[1],
            translateX: `${-x}%`,
            translateZ: 0,
        })
        .add({
            targets: this.wrap2Targets[1],
            translateX: `${x}%`,
            translateZ: 0,
        }, 1000)
        .add({
            targets: this.tileTargets[0],
            opacity: .3
        }, 1000)
    }

    write = () => {
        if (this.prevProgress === this.progress) return;
        this.prevProgress = this.progress;

        if (this.progress > 0.5) {
            this.select(1);
        } else {
            this.select(0);
        }

        this.tl.seek(this.tl.duration * this.progress);
    };

    select = (index) => {
        if (index === this.current) return;
        if (this.current !== null) {
            this.tileTargets[this.current].classList.remove(CLASS_CURRENT);
        }

        console.log('select', index);
        this.current = index;
        this.tileTargets[index].classList.add(CLASS_CURRENT);
    };

    initTile = (tile, index) => {

        const onEnter = () => {
            console.log('enter', index);
            this.anim.pause();
            this.anim = anime({
                targets: this,
                progress: index === 0 ? 0 : 1,
                duration: 1200,
                easing: 'easeOutExpo',
            });
        }

        const onLeave = () => {
            console.log('leave', index);
            this.anim.pause();
            this.anim = anime({
                targets: this,
                progress: 0.5,
                duration: 1200,
                easing: 'easeOutExpo',
            });
        }

        tile.addEventListener('mouseenter', onEnter);
        tile.addEventListener('mouseleave', onLeave);
    }
}