/*
    v1.6
    - Added disable fucntionality
    - Added disable Drag fucntionality
    - Added disable functionality for evenets

    v1.5
    - Added disabled drag animation option (dragAllowed)
    - Added diable js animation (isStatic)

    v1.4
    - Added select callback (call whatever function on new select (has index of the element as a parameter)
    - Added disable if frame is bigger than all slides
    
    v1.3
    Quick swipe:
        - if swiped small amount within 1 sec and released, go to next slide.
        - Swiping longer then 1 sec, only go next after dragged certain amount of px


    mode.fill: 
        overwritten by mode.centered
        overwritten by mode.infinite
    
    v.1.3
    Fixes:
    - Fix - this.indexMax wrong calculation if only one item in the frame
*/

import { Rect } from './../../utils/rect';
import { af as AF } from '@gladeye/af';

import Events from './_events';
import Dots from './_dots';
import Controls from './_controls';
import Animation from './_animation';
import Slides from './_slides';
import Autoplay from './_autoplay';

export default class OpSlider {
    index = 0;
    x = 0;
    xMin = 0;
    xMax = 0;
    indexMax = null;
    disabled = false;
    dragAllowed;
    isStatic;
    disabled = false;
    disableTimer = null;

    constructor(config) {
        this.slider = config.slider;
        this.list = config.list;
        this.frame = config.frame;
        this.rect = this.frame.getBoundingClientRect();
        this.length = config.slides.length;
        this.indexMax = this.length - 1;
        this.onSelectCallback = config.onSelect || null;
        this.dragAllowed = typeof config.dragAllowed === 'undefined' ? true : config.dragAllowed;
        this.isStatic = typeof config.isStatic === 'undefined' ? false : config.isStatic;
        this.disabled = typeof config.disabled === 'undefined' ? false : config.disabled;

        this.mode = {
            centered: config.mode.centered || false,
            infinite: config.mode.infinite || false,
            multiple: config.mode.multiple || false,
            updateDotsOnDrag: config.mode.updateDotsOnDrag || false,
            fill: config.mode.centered || config.mode.infinite ? false : config.mode.fill || false,  
            loop: config.mode.loop || false,
        };

        this.af = AF();

        this.onResize = this.onResize.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onDown = this.onDown.bind(this);
        this.onMove = this.onMove.bind(this);
        this.onUp = this.onUp.bind(this);
        this.tick = this.tick.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.select = this.select.bind(this);

        this.events = new Events({
            frame: this.slider,
            list: this.list,
            onDown: this.onDown,
            onMove: this.onMove,
            onUp: this.onUp
        });

        this.dots = new Dots({
            el: config.dots,
            length: this.length,
            onClick: this.select
        });

        this.controls = new Controls({
            slider: this.slider,
            nextItems: config.nextItems,
            prevItems: config.prevItems,
            next: this.next,
            prev: this.prev,
        });

        this.slides = new Slides({
            parent: this.list,
            els: config.slides,
            mode: this.mode
        });

        this.autoplay = new Autoplay({
            callback: this.next,
            settings: config.autoplay
        });
        this.autoplay.start();

        if (!this.isStatic) {
            this.animation = new Animation(this.list);
            this.onResize();
            window.addEventListener('resize', this.onResize);
            this.af.onNextRead(this.tick);
        }

        // this.slider.setAttribute('tabindex', 0);
        this.slider.addEventListener('focus', this.onFocus);
        this.slider.addEventListener('blur', this.onBlur);

        this.select(this.index, true);
    }

    disable() {
        this.disabled = true;
        this.events.disable();
    }

    enable() {
        this.disabled = false;
        this.events.enable();
    }

    disableDrag() {
        this.dragAllowed = false;
        this.events.disable();
    }

    enableDrag() {
        this.dragAllowed = true;
        this.events.enable();
    }

    onFocus() {
        // console.log('focused');
    }

    onBlur() {
        // console.log('Blured');
    }

    onResize() {
        // console.log('resize');
        let scope = this;
        // clearTimeout(this.resizeTimer);
        // this.resizeTimer = setTimeout(function() {
            scope.af.onNextRead(scope.tick);
            scope.select(scope.index);
        // }, 50);
    }

    tick() {
        this.rect = this.frame.getBoundingClientRect();
        this.slides.tick(this.rect.width);

        // If frame is bigger than it's contents, disable slider
        // No point dragging as it's already all visible
        // if (this.rect.width > this.slides.widthSum) this.disabled = true;
        // else this.disabled = false;

        this.xMin = this._calcX(this.length - 1);
        this.xMax = this._calcX(0);
    }

    _calcX(index) {
        const { slides: s, rect: r } = this;
        let x = 0,
            xFillFrame = r.width - s.widthSum;

        if (this.mode.centered) {
            x = r.width/2 - s.rects[index].width/2;
        }

        if (this.mode.infinite) {
            x -= this.slides.clones.left.width;
        }

        for (let i = 0; i < index; i++) {
            x -= s.rects[i].width;

            if (this.mode.fill && x < xFillFrame) {
                x = xFillFrame;
                this.indexMax = i + 1;
                break;
            }
        }

        return x;
    }

    onDown() {
        if (this.disabled) return;
        this.autoplay.stop();
    }

    onMove() {
        if (this.disabled) return;
        const { events: e, length: l } = this;
        let x = e.mouse.xDist + this._calcX(this.index);
        this.x = this._resist(x);

        if (!this.isStatic) {
            this.animation.drag(this.x);
        }

        if (this.mode.updateDotsOnDrag) {
            let index = this.slides.closestSlide(e.mouse.xDist, this.index, e.mouse.xDist > 0);
            const max = this.mode.fill ? this.indexMax : (l - 1);
            const min = 0;

            if (index < min){
                index = this.mode.infinite ? (l + index) : min;
            } else if (index > max) {
                index = this.mode.infinite ? (index - l) : max;
            }

            this.dots.select(index);
        }
    }

    _resist(x) {
        if (this.mode.infinite) return x;
        if (x > this.xMax) {
            return this.xMax + Math.sqrt(Math.abs(x - this.xMax)) * 20;
        } else if (x < this.xMin) {
            return this.xMin - Math.sqrt(Math.abs(x - this.xMin)) * 20;
        } else {
            return x;
        }
    }

    onUp() {
        if (this.disabled) return;
        if (this.events.mouse.xDist > 50) {
            this.prev(true);
        } else if (this.events.mouse.xDist < -50) {
            this.next(true);
        } else {
            this.select(this.index);
        }
    }

    prev(dragged = false) {
        if (this.disabled) return;
        let index = this.index - 1;

        if (this.mode.multiple & dragged) {
            index = this.slides.closestSlide(this.events.mouse.xDist, this.index, true);
        }

        if ((this.mode.infinite || this.mode.loop) && !this.isStatic) {
            if (index < 0){
                index = this.length - Math.abs(index % this.length);
                if (this.mode.infinite) this.animation.offset(this.slides.widthSum);
            }
        } else index = Math.max(index, 0);

        this.select(index);
    }

    next(dragged = false) {
        if (this.disabled) return;
        let index = this.index + 1;

        if (this.mode.multiple & dragged) {
            index = this.slides.closestSlide(this.events.mouse.xDist, this.index, false);
        }

        if ((this.mode.infinite || this.mode.loop) && !this.isStatic) {
            if (index > this.length - 1){
                index = index % this.length;
                if (this.mode.infinite) this.animation.offset(-this.slides.widthSum);
            }
        } else index = Math.min(index, this.length - 1);

        this.select(index);
    }

    select(index, instant = false) {
        this.af.onNextWrite(() => {
            this.index = index;

            if (this.mode.fill) {
                this.index = Math.min(index, this.indexMax);
            }


            this.dots.select(this.index);
            this.slides.select(this.index);

            if (!this.mode.infinite) {
                const max = this.mode.fill ? this.indexMax : (this.length - 1);
                const min = 0;
                let showNext = this.index < max, 
                    showPrev = this.index > min;
                this.controls.update(showNext, showPrev);
            }

            if (!this.disabled) {
                if (!this.isStatic) {
                    this.x = this._calcX(this.index);
                    if (instant) this.animation.jump(this.x);
                    else this.animation.release(this.x);
                    this.list.style.transform = 'translate3d(' + this.x + 'px, 0, 0)';
                }
            }

            this.autoplay.restart();

            if (this.onSelectCallback) this.onSelectCallback(index);
        });
    }
}