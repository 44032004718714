import { Controller } from "stimulus"

const CLASS_HIDDEN = 'is-hidden';
const CLASS_SELECTED = 'is-selected';

export default class extends Controller {
    static targets = [ "filter", "item" ];

    items = [];
    filters = [];
    selected = 0;

    timeoutID;

    connect() {
        this.init();
    }
    
    init = () => {

        this.itemTargets.forEach(item => {
            this.items.push({
                dom: item,
                categories: item.getAttribute('data-categories').split(',')
            });
        });

        this.filterTargets.forEach((filter, index) => {
            const key = filter.getAttribute('data-filter');
            const items = key === '*' ? this.items : this.items.filter(item => item.categories.some(cat => cat === key));
            this.filters.push({
                dom: filter,
                key,
                items
            });

            filter.addEventListener('click', () => this.handleClick(index));
        });
    }

    handleClick = (index) => {
        if (index === this.selected) return;
        const filter = this.filters[index];
        
        this.filters.forEach(filter => {
            filter.dom.classList.remove(CLASS_SELECTED);
        });
        
        this.selected = index;
        filter.dom.classList.add(CLASS_SELECTED);

        this.items.forEach(this.hide);

        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(() => {
            filter.items.forEach(this.show);
        }, 100)
    }

    hide = (item) => {
        item.dom.classList.add(CLASS_HIDDEN);
    }

    show = (item) => {
        item.dom.classList.remove(CLASS_HIDDEN);
    }
}