import { Controller } from "stimulus";
import { af as AF } from '@gladeye/af';

export default class extends Controller {
    static targets = ['sticky', 'link', 'nav', 'anchor', 'list'];
    active = false;
    className = 'is-sticky';

    activeSection = null;

    links = [];

    initialize() {
        this.af = AF();
    }

    connect() {
        if (!this.hasAnchorTarget || !this.hasNavTarget) {
            return;
        }

        this.navTarget.classList.add('is-nav-active');
        this.af.addRead(this.check);

        this.anchorTargets.forEach((anchor, index) => {
            const text = anchor.innerHTML.trim().replace(/<br\s*\/?>/gi,' ');
            const id = `${text.trim().replace(/\s+/g, '-').toLowerCase()}-${index}`;
            anchor.setAttribute('id', id);

            const li = document.createElement('li'); 
            li.classList.add('sticky-nav__item');

            const a = document.createElement('a'); 
            a.setAttribute('href', `#${id}`);
            a.innerHTML = text;

            li.appendChild(a);
            this.listTarget.appendChild(li);

            this.links.push({
                anchor,
                id,
                li,
                a,
            })
        });
    }

    check = () => {
        const rect = this.navTarget.getBoundingClientRect();
        
        if (rect.top <= 0) {
            this.af.onNextWrite(this.activate);
        } else {
            this.af.onNextWrite(this.deactivate);
        }

        this.checkLinks();
    }

    checkLinks() {
        const { innerHeight: wh } = window;
        const start = wh * 0.42;
        let selected = null;
        let selectedIndex = null

        for (let i = 0; i < this.links.length; i++) {
            const element = this.links[i].anchor;
            const rect = element.getBoundingClientRect();
            
            if (rect.top < start) {
                if (!selected) {
                    selected = { element, rect};
                    selectedIndex = i;
                } else {
                    if (rect.top > selected.rect.top) {
                        selected = { element, rect};
                        selectedIndex = i;
                    }
                }
            }
        }
        
        this.activateSection(selectedIndex);
    }

    activateSection(index) {
        if (index === this.activeSection) return;

        this.activeSection = index;
        this.links.forEach(link => link.li.classList.remove(`is-active`))

        if (index !== null) {
            this.links[index].li.classList.add(`is-active`);
        }
    }

    activate = () => {
        if (this.active) return;

        this.active = true;
        this.stickyTarget.classList.add(this.className);
    }

    deactivate = () => {
        if (!this.active) return;

        this.active = false;
        this.stickyTarget.classList.remove(this.className);
    }
}
