import { Controller } from "stimulus";
import validate from "validate.js";

const CLASS_VALUE = 'has-value';

export default class extends Controller {
    static targets = [
        "input"
    ];

    connect() {
        this.inputTarget.addEventListener('blur', this.onBlur);
    }

    onBlur = (item) => {
        const { value } = this.inputTarget;
        
        if (!validate.isEmpty(value)) {
            this.element.classList.add(CLASS_VALUE);
        } else {
            this.element.classList.remove(CLASS_VALUE);
        }
    };
}
