import { Controller } from "stimulus"
import "particles.js"

export default class extends Controller {

    connect() {
        this.init();
    }
    
    init = () => {
		const color = this.element.getAttribute('data-color');
		const id = this.element.getAttribute('id');

		const data = {
            "particles": {
              	"number": {
                	"value": 60,
                	"density": {
                  		"enable": true,
                  		"value_area": 570
                	}
              	},
              	"color": {
                	"value": color
              	},
              	"shape": {
                	"type": "circle",
					"stroke": {
                  		"width": 0,
                  		"color": "#000000"
                	},
              	},
              	"opacity": {
					"value": 1,
					"random": false,
					"anim": {
						"enable": false,
						"speed": 1,
						"opacity_min": 0.1,
						"sync": false
					}
              	},
              	"size": {
                	"value": 4,
                	"random": true,
					"anim": {
						"enable": false,
						"speed": 4,
						"size_min": 2,
						"sync": false
					}
              	},
				"line_linked": {
					"enable": true,
					"distance": 200,
					"color": color,
					"opacity": 1,
					"width": 1
				},
				"move": {
					"enable": true,
					"speed": 1,
					"direction": "none",
					"random": true,
					"straight": false,
					"out_mode": "bounce",
					"bounce": false,
					"attract": {
						"enable": false,
						"rotateX": 600,
						"rotateY": 1200
					}
				}
            },
            "interactivity": {
				"detect_on": "canvas",
				"events": {
					"onhover": {
						"enable": false,
						"mode": "grab"
					},
					"onclick": {
						"enable": false,
						"mode": "push"
					},
					"resize": true
				},
            },
            "retina_detect": true
		};
		
		switch (id) {
			case "home-particles-2":
				console.log('home-particle-2');
				data.particles.number = {
					"value": 10,
					"density": {
						"enable": true,
						"value_area": 60
					}
				};
			default:
				break;
		}

		particlesJS(id, data);
    }
}