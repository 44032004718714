import { Controller } from "stimulus";
import { af as AF } from '@gladeye/af';

export default class extends Controller {
    static targets = ['section'];
    active = null;
    modifier = null;
    // start = 300;

    initialize() {
        this.af = AF();
    }

    connect() {
        if (this.sectionTargets.length > 0) {
            this.handleScroll();
            window.addEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll = () => {
        this.af.onNextRead(this.checkAll);
    }

    checkAll = () => {
        const { innerHeight: wh } = window;
        const start = wh * 0.42;
        const s = this.sectionTargets;
        let selected = null;
        let selectedIndex = null

        for (let i = 0; i < s.length; i++) {
            const element = s[i];
            const rect = element.getBoundingClientRect();
            
            if (rect.top < start) {
                if (!selected) {
                    selected = { element, rect};
                    selectedIndex = i;
                } else {
                    if (rect.top > selected.rect.top) {
                        selected = { element, rect};
                        selectedIndex = i;
                    }
                }
            }
        }
        
        this.activateSection(selectedIndex);
    }

    activateSection(index) {
        if (index === this.active) return;

        this.active = index;
        this.element.classList.remove(`is-${this.modifier}`);

        if (index !== null) {
            const section = this.sectionTargets[index];
            this.modifier = section.getAttribute('data-color');
            this.element.classList.add(`is-${this.modifier}`);
        } else {
            this.modifier = null;
        }
    }
}
