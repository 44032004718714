import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [];
    
    connect() {
    }

    toggle = (dropdown, level) => {
        this.element.classList.toggle(`is-menu-opened`);
    };
}
