// import "core-js/fn/promise";
// import "core-js/fn/array/from";
// import "core-js/fn/array/fill";

// import "core-js/fn/array/find"
// import "core-js/fn/array/find-index"
// import "core-js/fn/map"
// import "core-js/fn/object/assign"
// import "core-js/fn/set"

import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';

// import "mutation-observer-inner-html-shim"
// import "isomorphic-fetch"

// import "element-closest/browser";

// if (!Element.prototype.matches) {
//     Element.prototype.matches = Element.prototype.msMatchesSelector;
// }

// import smoothscroll from "smoothscroll-polyfill";
// import "objectFitPolyfill";

// smoothscroll.polyfill();
// objectFitPolyfill();