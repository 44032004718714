import { Controller } from "stimulus";
import OpSlider from "./../components/opSlider/opSlider";

const CLASS_ACTIVE = "is-active";

export default class extends Controller {
    resizeTimer = null;

    static targets = [
        "frame",
        "list",
        "item",
    ];
    
    initialize() {
        this.onResize = this.onResize.bind(this);
        this.tick = this.tick.bind(this);
    }

    connect() {
        if(!this.hasItemTarget) return;
        
        this.length = this.itemTargets.length;

        let config = {
            slider: this.element,
            frame: this.frameTarget,
            list: this.listTarget,
            slides: this.itemTargets,
            disabled: true,
            mode: {
                fill: true,
                multiple: true
            }
        };

        this.slider = new OpSlider(config);
        this.tick();
        window.addEventListener('resize', this.onResize);
    }

    onResize() {
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(this.tick, 250);
    }

    tick() {
        const { innerWidth: w } = window;
        const rectLast = this.itemTargets[this.length - 1].getBoundingClientRect();
        const rectSlider = this.element.getBoundingClientRect();
        
        if (rectLast.right < w) {
            this.slider.disable();
            this.element.classList.remove(CLASS_ACTIVE);
        } else {
            this.slider.enable();
            this.element.classList.add(CLASS_ACTIVE);
        }
    }
}
